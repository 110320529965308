import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Badge,
  Link,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// third-party

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconBell } from "@tabler/icons";
import { IconMessage } from "@tabler/icons";
import { useNavigate } from "react-router";
import ClientAvatar from "ui-component/ClientAvatar";
import { loadUnreadMessages } from "store/actions/navbar";
import { formatChatDate, formatChatTime } from "utils/common";
import { MENU_OPEN } from "store/actions";

// notification status options

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const unreadMessages = useSelector((state) => state.navbar).unreadMessages;
  const [notificationsArray, setNotificationsArray] = useState([]);

  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggleMail = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    dispatch(loadUnreadMessages())
  }, [dispatch]);

  useEffect(() => {
    //dispatch({ type: "SELECT_CHAT_ROOM_ID", data: 0 });
    let nArray = [];
    //console.log('unreadMessages', unreadMessages);
    unreadMessages.map((item, index) => {
      nArray.push({
        created_at: formatChatTime(parseFloat(item.messages[item.messages.length-1].created_at)) + ', ' + formatChatDate(parseFloat(item.messages[item.messages.length-1].created_at)),
        count: item.messages.length,
        room_id: item.messages[item.messages.length-1].room_id,
        last_message: item.messages[item.messages.length-1].message,
        fullName: getFullName(item)
      });
    });
    setNotificationsArray(nArray);
  }, [unreadMessages]);

  const getFullName = (item) => {
    if (item.first_name && item.last_name) {
      return item.first_name + ' ' + item.last_name
    }
    if (item.first_name) {
      return item.first_name
    }
    if (item.last_name) {
      return item.last_name
    }
    return item.email
  }

  return (
    <>
      <Box
        sx={{
          ml: 2,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <Badge
          badgeContent={
            notificationsArray.length === 0 ? null : notificationsArray.length
          }
          color="primary"
        >
          <ButtonBase sx={{ borderRadius: "12px" }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: "all .2s ease-in-out",
                background: theme.palette.common.black,
                color: theme.palette.primary.light,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.light,
                },
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggleMail}
              color="inherit"
            >
              <IconMessage stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Badge>
      </Box>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    sx={{ p: 2, width: "320px" }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h4">New Messages</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          height: "100%",
                          maxHeight: "50vh",
                          overflowX: "hidden",
                        }}
                      >
                        {notificationsArray.length > 0 ? (
                          notificationsArray.map((item, index) => {
                            return (
                              <>
                                <Stack
                                  key={index}
                                  direction="row"
                                  spacing={2}
                                  sx={{ alignItems: "flex-start", mt: 1.5, mb: 1 }}
                                >
                                  <ClientAvatar number={item.count} status />
                                  <Box sx={{ ml: 2 }}>
                                    <Link
                                      href="#"
                                      variant="h4"
                                      underline="none"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch({
                                          type: "SELECT_CHAT_ROOM_ID",
                                          data: item.room_id,
                                        });
                                        navigate("/chat");
                                        handleToggleMail();
                                      }}
                                    >
                                      {item.fullName}
                                    </Link>
                                    <Typography
                                      variant="body1"
                                      color={theme.palette.text.dark}
                                    >
                                      {item.count} unread
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      color={theme.palette.text.dark}
                                    >
                                      {item.created_at}
                                    </Typography>
                                  </Box>
                                </Stack>
                                { index < notificationsArray.length - 1 && (
                                  <Divider />
                                )}
                              </>
                            );
                          })
                        ) : (
                          <Typography variant="body2">
                            There are no unread messages
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
                    <Button
                      size="small"
                      onClick={() => {
                        handleToggleMail();
                        dispatch({ type: MENU_OPEN, id: 'chat' });
                        navigate("/chat");
                      }}
                    >
                      Read All Messages
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
